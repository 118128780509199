<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-btn small fab dark color="info" @click="$router.go(-1)" class="mx-1">
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              id="unpaidInvoicesList"
              ref="unpaidInvoicesList"
              class="lg-table"
              :headers="[
                { text: 'OIB', value: 'id' },
                { text: 'Kupac', value: 'recipient', width: 250 },
                { text: 'Račun', value: 'invoice_num' },
                { text: 'Iznos', value: 'amount_total', align: 'end' },
                { text: 'Plaćeno', value: 'amount_paid', align: 'end' },
                { text: 'Rok plaćanja', value: 'due_date' },
                { text: 'Izvod', value: 'statement_num' }
              ]"
              apiLoad="invoice/unpaidinvoices.php"
              :actions="[
                {
                  id: '1',
                  name: 'Preuzmi PDF podsjetnika na nepodmirene obaveze',
                  apiDownloadCall: 'invoice/unpaidinvoice.php?format=pdf',
                  icon: 'picture_as_pdf',
                  color: 'error'
                }
              ]"
              defaultFilter="recipient"
              defaultSort="id"
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "UnpaidInvoicesList",
  data() {
    return {};
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.unpaidInvoicesListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.unpaidInvoicesList.reload();
      this.$store.dispatch("unpaid_invoices_list_refreshed");
    }
  }
};
</script>
