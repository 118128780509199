var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"info"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("arrow_back_ios")])],1),_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"unpaidInvoicesList",staticClass:"lg-table",attrs:{"id":"unpaidInvoicesList","headers":[
              { text: 'OIB', value: 'id' },
              { text: 'Kupac', value: 'recipient', width: 250 },
              { text: 'Račun', value: 'invoice_num' },
              { text: 'Iznos', value: 'amount_total', align: 'end' },
              { text: 'Plaćeno', value: 'amount_paid', align: 'end' },
              { text: 'Rok plaćanja', value: 'due_date' },
              { text: 'Izvod', value: 'statement_num' }
            ],"apiLoad":"invoice/unpaidinvoices.php","actions":[
              {
                id: '1',
                name: 'Preuzmi PDF podsjetnika na nepodmirene obaveze',
                apiDownloadCall: 'invoice/unpaidinvoice.php?format=pdf',
                icon: 'picture_as_pdf',
                color: 'error'
              }
            ],"defaultFilter":"recipient","defaultSort":"id"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }